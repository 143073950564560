import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import logoImage from "../logo.svg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <nav className="sticky z-50 transition duration-300 bg-white">
    <div className="container text-base flex items-center justify-between lg:px-10 px-5 py-2 mx-auto">
  <a to="/" className="flex flex-row items-center justify-start p-2">
    <img src={logoImage} alt="Logo" className="mr-2" />{" "}
    <h1 className="font-bold text-base">MINER GATE</h1>
  </a>
  <div className="hidden md:flex items-center space-x-6">
  <div className="space-x-12 font-semibold">
    <a href="#platform">Platform</a>
    <a href="#dashboard">Dashboard</a>
    <a href="#roadmap">Roadmap</a>
    <a href="#tooling">Tooling</a>
  </div>
    <Button
      onClick={handleOpen}
      variant="gradient"
      className="px-3 py-2 transition duration-300 rounded-full bg-black text-white"
    >
      Launch App
    </Button>
    <Dialog open={open} handler={handleOpen} className="flex flex-col">
      <DialogHeader className="text-center">Coming Soon</DialogHeader>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen}
          className="p-2 bg-rose-600 rounded-lg"
        >
          <span>Cancel</span>
        </Button>
      </DialogFooter>
    </Dialog>
  </div>
  <div className="md:hidden">
    <button onClick={toggleMenu} className="focus:outline-none">
      <svg
        className="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </button>
  </div>
</div>


      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="py-2 md:hidden bg-white">
          <a href="https://minergate.xyz/rpc" className="block px-4 py-2 ">
            Platform
          </a>
          <a href="#dashboard" className="block px-4 py-2 ">
            Dashboard
          </a>
          <a href="#roadmap" className="block px-4 py-2 ">
            Roadmap
          </a>
          <a href="#tooling" className="block px-4 py-2">
            Tooling
          </a>
          <Button
            onClick={handleOpen}
            variant="gradient"
            className="px-3 py-2 transition duration-300 rounded-full bg-black text-white"
          >
            Launch App
          </Button>
          <Dialog
            open={open}
            handler={handleOpen}
            className="flex flex-col"
          >
            <DialogHeader className="text-center">Coming Soon</DialogHeader>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={handleOpen}
                className="p-2 bg-rose-600 rounded-lg"
              >
                <span>Cancel</span>
              </Button>
            </DialogFooter>
          </Dialog>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
