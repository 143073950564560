import React, { useRef, useEffect, useState } from "react";

const ToolingDesc = () => {

    const sectionRef = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [sectionHeight, setSectionHeight] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        if (sectionRef.current) {
          const { top, height } = sectionRef.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
  
          if (top <= windowHeight && top + height >= 0) {
            const newHeight = Math.max(0, windowHeight - (top + height)) + windowHeight - Math.max(0, top);
            setScrollHeight(newHeight);
          }
        }
      };
  
      const updateSectionHeight = () => {
        if (sectionRef.current) {
          setSectionHeight(sectionRef.current.getBoundingClientRect().height);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateSectionHeight);
  
      updateSectionHeight(); // Calculate initial section height
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateSectionHeight);
      };
    }, []);

    return (
        <section
            className="relative h-full"
            style={{ backgroundColor: "#0d1117" }}
        >
            
             <div className="flex text-white text-xl font-normal pt-8 lg:pl-32 md:pl-16" id="tooling">
                <div className="w-8">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 32"><path fill="#fff" d="M10.1 2.333A2.334 2.334 0 0 1 12.434 0h7.333A2.334 2.334 0 0 1 22.1 2.333v3h6.334a2.334 2.334 0 0 1 2.333 2.334V27a2.333 2.333 0 0 1-2.333 2.333H3.767A2.333 2.333 0 0 1 1.434 27V7.667a2.334 2.334 0 0 1 2.333-2.334H10.1v-3ZM3.434 15.987V27c0 .184.149.333.333.333h24.667a.333.333 0 0 0 .333-.333V15.987a5.646 5.646 0 0 1-3.667 1.346h-18a5.647 5.647 0 0 1-3.666-1.346Zm25.333-4.32v-4a.333.333 0 0 0-.333-.334H3.767a.333.333 0 0 0-.333.334v4A3.667 3.667 0 0 0 7.1 15.333h18a3.667 3.667 0 0 0 3.667-3.666ZM20.1 2.333A.333.333 0 0 0 19.767 2h-7.333a.333.333 0 0 0-.334.333v3h8v-3Z"></path></svg>
                </div>
                <p className="pl-8">Tooling</p>
            </div>
            <div
      ref={sectionRef}>
      {/* Vertical line */}
      <div
        className="absolute lg:left-36 left-1 w-1"
        style={{
          transition: "height 0.5s",
          height: `${scrollHeight}px`,
          maxHeight: `${sectionHeight}px`,
          background: "linear-gradient(rgb(86, 211, 100) 0%, rgb(46, 160, 67) 50%, rgb(13, 17, 23) 100%)"
        }}
      ></div>
            <div className="pt-6 lg:pl-48 pl-24 pb-6">
                <p className="lg:text-5xl text-xl" style={{ color: "#7EE787" }}>
                The complete web3 stack.{" "}
                    <span className="text-white">
                    Set up SDK integrations, deploy smart contract, launch dApps, manage gas fees, data, and more.
                    </span>
                </p>
            </div> 
            
        </div>
        </section>
    );
};

export default ToolingDesc;
