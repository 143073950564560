import React from "react";
import MgtBanner from "../assets/HomeMgtBanner.webp"

const Dashboard = () => {
    return (
        <section className="h-full lg:p-32 lg:pb-0 md:p-16 p-4" id="dashboard">
            <div className="">
                <h1 className="lg:text-6xl text-3xl font-bold pr-20 leading-tight mb-10">
                    <span
                        style={{
                            WebkitTextFillColor: "transparent",
                            WebkitBackgroundClip: "text",
                            backgroundImage:
                                "linear-gradient(185deg, #FF7B72 9.45%, #D29922 89.02%)",
                        }}
                    >
                        MinerGate<br />

                    </span>
                    User<br />
                    Dashboard
                </h1>
                <p className="text-slate-500 text-2xl font-normal lg:w-1/2 lg:pr-10">
                Manage all your MGT tokens and credits in one place with the MGT dashboard.
                </p>
            </div>
            <img src={MgtBanner} alt="MgtBanner" />
        </section>
    )
}

export default Dashboard;