import React, { useRef, useEffect, useState } from "react";
import FiveBlocks from "../assets/FiveBlocksConnected.webp";
import BitcoinCook from "../assets/BitcoinCook.webp";
import BlackCalculator from "../assets/BlackCalculator.webp";
import BlackSettings from "../assets/BlackSettings.webp";

const ChooseUs = () => {

    const sectionRef = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [sectionHeight, setSectionHeight] = useState(0);
    
  
    useEffect(() => {
      const handleScroll = () => {
        if (sectionRef.current) {
          const { top, height } = sectionRef.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
  
          if (top <= windowHeight && top + height >= 0) {
            const newHeight = Math.max(0, windowHeight - (top + height)) + windowHeight - Math.max(0, top);
            setScrollHeight(newHeight);
          }
        }
      };
  
      const updateSectionHeight = () => {
        if (sectionRef.current) {
          setSectionHeight(sectionRef.current.getBoundingClientRect().height);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateSectionHeight);
  
      updateSectionHeight(); // Calculate initial section height
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateSectionHeight);
      };
    }, []);

    return (
        <section
            className="relative h-full"
            style={{ backgroundColor: "#0d1117" }}
        >
            <div className="flex text-white text-xl font-normal pt-4 lg:pl-32 md:pl-16">
                <div className="w-8">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 33 33"
                    >
                        <path
                            fill="#fff"
                            d="M20.397 7.296a1 1 0 0 1 1.413 0l8.667 8.667a1 1 0 0 1 0 1.413l-8.667 8.667a.999.999 0 0 1-1.691-.963.998.998 0 0 1 .278-.45l7.96-7.96-7.96-7.96a1 1 0 0 1 0-1.414Zm-8.587 0a1 1 0 0 1 0 1.414l-7.96 7.96 7.96 7.96a.999.999 0 1 1-1.413 1.413L1.73 17.376a1 1 0 0 1 0-1.413l8.667-8.667a1 1 0 0 1 1.413 0Z"
                        ></path>
                    </svg>
                </div>
                <p className="pl-8">Why choose MinerGate Tools</p>
            </div>
            <div
      ref={sectionRef}>
      {/* Vertical line */}
      <div
        className="absolute lg:left-36 left-1 w-1"
        style={{
          transition: "height 0.5s",
          height: `${scrollHeight}px`,
          maxHeight: `${sectionHeight}px`,
          background: "linear-gradient(rgb(210, 168, 255) 0%, rgb(163, 113, 247) 10%, rgb(25, 108, 46) 60%, rgb(46, 160, 67) 70%, rgb(86, 211, 100) 80%)"
        }}
      ></div>
            <div className="pt-6 lg:pl-48 pl-24">
                <p className="lg:text-5xl text-xl" style={{ color: "#D2A8FF" }}>
                    MinerGate is a modular web3 system that supports open standards,
                    Ethereum-compatible networks, and development tools.{" "}
                    <span className="text-white">
                        MinerGate lets you use one module or the whole stack. MinerGate can
                        also be used with your favorite developer tools.
                    </span>
                </p>
                <div className="flex flex-col lg:flex-row pt-4 mt-10 gap-8">
                    <div className="lg:w-1/4 md:pt-2">

                        <img src={FiveBlocks} alt="FiveBlocks" className="w-32 h-24" />
                        <h1 className="text-white text-xl mb-4">
                            Ethereum Compatibilitbase</h1>
                        <p className="text-white text-base">Building compatible systems and tools to extend the capabilities of any Ethereum / EVM compatible blockchain network</p>
                    </div>
                    <div className="lg:w-1/4 md:pt-2">

                        <img src={BitcoinCook} alt="BitcoinCook" className="w-32 h-24" />
                        <h1 className="text-white text-xl mb-4">Multi-Chain Support</h1>
                        <p className="text-white text-base">Support all EVM compatible chains</p>
                    </div>
                    <div className="lg:w-1/4 md:pt-2">

                        <img src={BlackCalculator} alt="BlackCalculator" className="w-32 h-24" />
                        <h1 className="text-white text-xl mb-4">Easy Installation</h1>
                        <p className="text-white text-base">MinerGate's tools are simple to use and install on the user's end.</p>
                    </div>
                    <div className="lg:w-1/4 md:pt-2">

                        <img src={BlackSettings} alt="BlackSettings" className="w-32 h-24" />
                        <h1 className="text-white text-xl mb-4">Professional team</h1>
                        <p className="text-white text-base">Premium tooling sites and professional operation and maintenance team.</p>
                    </div>
                </div>
            </div>
            
            </div>
        </section>
    );
};

export default ChooseUs;
