import React from 'react';
import CloudConsole from "../assets/CloudConsoleUI.webp"

const CloudConsoleBox = () => {
    return (
        <section className='h-full justify-center items-center lg:py-20 lg:pl-20' style={{ backgroundColor: "#0d1117" }}>
            <div className='lg:pt-2 pt-6'>

                <div className='border-2 border-gray-700 bg-gray-800 rounded-lg p-10 lg:mx-2 mx-4'>
                <div className='flex flex-col lg:flex-row'>
                    <div className='lg:w-1/3 lg:p-5'>

                <h1 className='lg:text-3xl md:text-2xl text-white font-normal lg:pb-2'>Console UI</h1>
                        <p className='text-xl text-slate-400 font-normal lg:mt-5 md:pb-2'>Deploy your infrastructure with our web-based console.</p>
                </div>
                <div className='lg:w-2/3'>

                <img src={CloudConsole} alt='CloudConsole'className='lg:h-80 w-full'/>
                </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CloudConsoleBox;
