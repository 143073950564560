import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import video from "../assets/heroVid.mp4";

const Hero = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <section className="relative h-screen overflow-hidden" id="platform">
      <div className="absolute z-10 pl-10 pt-16 lg:pl-32 lg:pt-24 h-screen">
        <h1 className="mb-10 font-bold text-4xl lg:text-5xl text-white">
          Secure WEB3
          <br />
          with MinerGate Tools
        </h1>
        <p className="lg:text-base mb-10 text-xl text-white">
          MinerGate is an essential second layer of web3
          <br /> security, providing unrestricted, business and <br />
          user-centric scope of cybersecurity solutions.<br/>
          CA: 0x6534DC9cA044C8582B4604726125e0Ea2D6814e5
        </p>
        <Button
          onClick={handleOpen}
          variant="gradient"
          className="px-8 py-2 transition duration-300 rounded-full text-sm bg-white text-black"
        >
          TRY OUR TOOL
        </Button>
        <Dialog
          open={open}
          handler={handleOpen}
          className="flex flex-col items-center justify-center"
        >
          <DialogHeader className="text-center">Coming Soon</DialogHeader>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="p-2 bg-rose-600 rounded-lg"
            >
              <span>Cancel</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 z-0 object-cover w-full h-full"
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="absolute inset-0 z-0 opacity-50 bg-palette-color-5"></div>
    </section>
  );
};

export default Hero;
