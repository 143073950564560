import React, { useRef, useEffect, useState } from "react";

const Build = () => {
    const sectionRef = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [sectionHeight, setSectionHeight] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        const { top, height } = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
  
        if (top <= windowHeight && top + height >= 0) {
          const newHeight = Math.max(0, windowHeight - (top + height)) + windowHeight - Math.max(0, top);
          setScrollHeight(newHeight);
        }
      };
  
      const updateSectionHeight = () => {
        setSectionHeight(sectionRef.current.getBoundingClientRect().height);
      };
  
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateSectionHeight);
  
      updateSectionHeight(); // Calculate initial section height
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateSectionHeight);
      };
    }, []);

    return (
        <section
            className="relative h-full"
            style={{ backgroundColor: "#0d1117" }}
        >
            <div className="flex text-white text-xl font-normal lg:pl-32 md:pl-16">
                <div className="w-8">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path fill="#fff" d="M8 11.999V9.665c0-4.54 3.337-8.333 8-8.333s8 3.793 8 8.333V12h.667A3.333 3.333 0 0 1 28 15.332v10.667a3.333 3.333 0 0 1-3.333 3.333H7.333A3.333 3.333 0 0 1 4 25.999V15.332a3.333 3.333 0 0 1 3.333-3.333H8Zm-2 3.333v10.667a1.333 1.333 0 0 0 1.333 1.333h17.334A1.333 1.333 0 0 0 26 25.999V15.332a1.334 1.334 0 0 0-1.333-1.333H7.333A1.334 1.334 0 0 0 6 15.332Zm4-5.667V12h12V9.665c0-3.56-2.563-6.333-6-6.333s-6 2.773-6 6.333Z"></path></svg>
                </div>
                <p className="pl-8">Build</p>
            </div>
            <div
      ref={sectionRef}>
      {/* Vertical line */}
      <div
        className="absolute lg:left-36 md:left-16 left-1 w-1"
        style={{
          transition: "height 0.5s",
          height: `${scrollHeight}px`,
          maxHeight: `${sectionHeight}px`,
          background: "linear-gradient(rgb(171, 180, 255) 0%, rgb(121, 126, 249) 50%, rgb(13, 17, 23) 100%)"
        }}
      ></div>
            <div className="pt-4 lg:pl-48 pl-24 pb-6">
                <p className="lg:text-5xl text-xl text-white">
                    MinerGate's all-inclusive tool for managing your projects, API keys,{" "}
                    <span style={{ color: "#D2A8FF" }}>
                        as well as contract and marketplace deployments.
                    </span>
                </p>
                <div className="flex flex-col lg:flex-row lg:gap-4 pr-4">
                <div className='lg:w-1/2 border-2 border-gray-700 bg-gray-800 rounded-lg lg:p-8 md:p-4 mt-10 '>
                        <h1 className='text-xl text-white font-medium'>Mobile SDK</h1>
                        <p className='text-base text-slate-400 mt-5'>Equip your mobile app with web3 superpowers using the MinerGate Mobile SDK. No blockchain experience required.</p>
                    </div>
                <div className='lg:w-1/2 border-2 border-gray-700 bg-gray-800 rounded-lg lg:p-8 md:p-4 mt-10'>
                        <h1 className='text-xl text-white font-medium'>Virtual machines</h1>
                        <p className='text-base text-slate-400 mt-5'>Deploy virtual machines on the cloud with a diverse range of machine types.</p>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-4 pr-4">
                <div className='lg:w-1/2 border-2 border-gray-700 bg-gray-800 rounded-lg lg:p-8 md:p-4 mt-10 '>
                        <h1 className='text-xl text-white font-medium'>Node Gateway</h1>
                        <p className='text-base text-slate-400 mt-5'>The world's best node service with 99.9% uptime. Real-time and reliable access to blockchain data, ensuring accurate, up-to-date, and always available stats.</p>
                    </div>
                <div className='lg:w-1/2 border-2 border-gray-700 bg-gray-800 rounded-lg lg:p-8 md:p-4 mt-10'>
                        <h1 className='text-xl text-white font-medium'>Networking</h1>
                        <p className='text-base text-slate-400 mt-5'>Ultra-fast private networking solution that abstracts the complexity of network configuration.</p>
                    </div>
                </div>
            </div>
            </div>
        </section>
    );
};

export default Build;
