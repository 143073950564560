import React from "react";
import Choose2 from "../assets/Chooseus2.png";

const ChooseBg = () => {
    return (
        <section>
            <img src={Choose2} alt="Choose Us Bg"/>
        </section>
    );
};

export default ChooseBg;
