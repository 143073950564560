import React from 'react';
import risk from "../assets/riskscore.png";

const CustomComponent = () => {
    return (
        <section className="flex flex-col justify-center items-center h-screen">
            <div className="bg-black w-5/6 lg:w-3/4 rounded-t-lg">
                <div className="flex sm:gap-x-4 md:gap-x-14 flex-row justify-center items-center lg:gap-32">
                    <div className="flex items-center flex-col">
                        <button className="bg-transparent border-solid border-transparent border-solid focus:border-blue-light">
                            <span>
                                <h1 className="text-white text-center text-xs md:text-sm lg:text-lg font-semibold" style={{ color: '#D2A8FF' }}>Risk Scoring</h1>
                            </span>
                        </button>
                        <div className="lg:m-2 md:m-2">
                            <img src={risk} alt='risk score' />
                        </div>
                    </div>
                    <div className="flex items-center gap-1">
                        <div className="bg-white rounded-full w-1 h-1"></div>
                        <div className="bg-white rounded-full w-1 h-1"></div>
                        <div className="bg-white rounded-full w-1 h-1"></div>
                    </div>
                    <button className="bg-transparent border-solid border-transparent border-solid focus:border-blue-light">
                        <span>
                            <h1 className="text-white text-center text-xs md:text-sm lg:text-lg font-semibold" >LP Monitor</h1>
                        </span>
                    </button>
                    <div className="flex items-center gap-1">
                        <div className="bg-white rounded-full w-1 h-1"></div>
                        <div className="bg-white rounded-full w-1 h-1"></div>
                        <div className="bg-white rounded-full w-1 h-1"></div>
                    </div>
                    <button className="bg-transparent border-solid border-transparent border-solid focus:border-blue-light">
                        <span>
                            <h1 className="text-white text-center text-xs md:text-sm lg:text-lg font-semibold" >Data Aggregation</h1>
                        </span>
                    </button>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col w-5/6 lg:w-3/4 '>
                <div className='lg:w-1/2 lg:pr-28 md:pr-40'>
                    <h1 className="lg:text-4xl text-3xl font-bold py-8" >On-chain Risk Scoring Protocol</h1>
                    <p className='md:text-base'>MinerGatemalicious activity in the blockchain space. It utilizes external and on-chain data to provide accurate information on compromised wallets and malicious activities. MinerGate can be integrated into DEXes and DeFi protocols to prevent money laundering and enhance cybersecurity.</p>
                </div>
                <div className='lg:w-1/2 lg:p-8 md:pt-8'>
                    <div className='bg-slate-100 w-full rounded-lg p-2 flex items-center justify-between mb-4'>
                        <h1 className='text-4xl text-start font-semibold '>7</h1>
                        <p className='text-end font-medium text-slate-600'>Supported</p>
                    </div>
                    <div className='bg-slate-100 w-full rounded-lg p-2 flex items-center justify-between mb-4'>
                        <h1 className='text-4xl text-start font-semibold '>400K+</h1>
                        <p className='text-end font-medium text-slate-600'>API Calls/Month</p>
                    </div>
                    <div className='bg-slate-100 w-full rounded-lg p-2 flex items-center justify-between mb-4'>
                        <h1 className='text-4xl text-start font-semibold '>65</h1>
                        <p className='text-end font-medium text-slate-600'>Total Cases</p>
                    </div>
                    <div className='bg-slate-100 w-full rounded-lg p-2 flex items-center justify-between'>
                        <h1 className='text-4xl text-start font-semibold '>10850</h1>
                        <p className='text-end font-medium text-slate-600'>Total Addresses</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomComponent;
