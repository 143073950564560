import React from "react";
import Logo from "../assets/Logo.svg"

const Footer = () => {
    return (
        <section className="h-full lg:p-16 p-6" style={{backgroundColor: "#090A18"}}>
            <div className="flex flex-col">
            <svg viewBox="0 0 1816 162" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1815.28 39.7876H1686.49V61.176H1799.18V100.503H1686.49V121.892H1815.28V161.219H1638.2V0.230469H1815.28V39.7876Z" fill="#7540EF"></path><path d="M1637.68 0.230469V43.2373H1565.23V161.219H1516.94V43.2373H1444.49V0.230469H1637.68Z" fill="#7540EF"></path><path d="M1426.27 161.219L1412.7 129.021H1309.44L1295.87 161.219H1240.44L1308.29 0.230469H1413.85L1481.92 161.219H1426.27ZM1325.99 89.6939H1396.14L1361.18 6.67L1325.99 89.6939Z" fill="#7540EF"></path><path d="M1120.83 41.3975C1098.98 41.3975 1081.5 59.1062 1081.5 80.7246C1081.5 102.573 1098.98 120.282 1120.83 120.282H1194.19V100.503H1129.8V61.176H1242.49V161.219H1113.7C1069.08 161.219 1033.2 125.341 1033.2 80.7246C1033.2 36.3378 1069.08 0.230469 1113.7 0.230469H1242.49V41.3975H1120.83Z" fill="#7540EF"></path><path d="M959.755 58.4162C959.755 83.0244 944.576 103.953 922.957 112.462L959.755 161.219H904.099L870.751 116.602H798.766V161.219H750.47V0.230469H901.569C933.537 0.230469 959.755 26.4486 959.755 58.4162ZM798.766 41.3975V75.435H894.439C903.639 75.435 911.458 67.8455 911.458 58.4162C911.458 48.9869 903.639 41.3975 894.439 41.3975H798.766Z" fill="#7540EF"></path><path d="M743.545 39.7876H614.754V61.176H727.446V100.503H614.754V121.892H743.545V161.219H566.458V0.230469H743.545V39.7876Z" fill="#7540EF"></path><path d="M506.598 0.230469H554.895V161.219H458.302L393.907 12.6496V161.219H345.61V0.230469H442.203L506.598 149.03V0.230469Z" fill="#7540EF"></path><path d="M334.094 161.219H285.797V0.230469H334.094V161.219Z" fill="#7540EF"></path><path d="M179.414 0.230469H274.397V161.219H226.1V19.5491L186.543 161.219H88.3403L49.0131 19.5491V161.219H0.716675V0.230469H95.4697L137.557 151.559L179.414 0.230469Z" fill="#7540EF"></path></svg>
            <div className="flex flex-col lg:flex-row gap-10">
                <div className="flex flex-col items-center justify-center p-20">

                <img src={Logo} alt="Logo" className="w-48 items-center justify-center"/>
                <h1 className="text-3xl font-extrabold text-center text-white">MINER GATE</h1>
                </div>
                <div className="border-2 border-gray-700 rounded-lg p-5 mt-10 h-full text-white lg:w-72 md:w-full" style={{backgroundColor: "rgba(255, 255, 255, 0.04)"}}>
                    <p className="bg-gray-600 rounded-lg font-medium p-1">Socials</p>
                    <div className="flex flex-col leading-loose pt-2">
                    {/* <a href="/">Medium</a> */}
                    <a href="https://t.me/MINERGATETOKEN">Telegram</a>
                    <a href="https://twitter.com/MinerGateToken ">Twitter</a>
                    {/* <a href="/">Youtube</a> */}
                    </div>
                </div>
                <div className="border-2 border-gray-700 rounded-lg p-5 mt-10 h-full text-white lg:w-72 md:w-full" style={{backgroundColor: "rgba(255, 255, 255, 0.04)"}}>
                    <p className="bg-gray-600 rounded-lg font-medium p-1">Developers</p>
                    <div className="flex flex-col leading-loose pt-2">
                    <a href="/">Developer Tools</a>
                    <a href="/">Developer Docs</a>
                    </div>
                </div>
                <div className="border-2 border-gray-700 rounded-lg p-5 mt-10 h-full text-white lg:w-72 md:w-full" style={{backgroundColor: "rgba(255, 255, 255, 0.04)"}}>
                    <p className="bg-gray-600 rounded-lg font-medium p-1">Navigation</p>
                    <div className="flex flex-col leading-loose pt-2">
                    <a href="/">Main</a>
                    <a href="#platform">Platform</a>
                    <a href="#dashboard">Dashboard</a>
                    <a href="#roadmap">Roadmap</a>
                    <a href="#tooling">Tooling</a>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Footer;