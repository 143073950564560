import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Roadmap = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show on desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="h-full lg:p-32 md:p-16 sm:p-5" id="roadmap">
      <h1 className="lg:text-6xl md:text-3xl font-bold pr-20 leading-tight mb-10">
        Roadmap
      </h1>

      <Slider {...settings}>
        
        <div className="border-2 border-gray-300 rounded-lg h-full flex flex-col p-4 text-left leading-loose">
          <h1
            className=" md:text-xl font-bold lg:pr-20 leading-tight mb-10"
            style={{ color: "#6C01D4" }}
          >
            Phase 2: Core Development
          </h1>
          <p className="font-medium">Smart Contract Development</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Begin building the core MinerGate Protocol smart contracts.</p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Focus on the functionality of data categorization and risk
              assessment.
            </p>
          </div>
          <p className="font-medium">Machine Learning Integration</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Develop machine learning algorithms for risk assessment and
              categorization.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Implement data-driven models for enhanced accuracy.</p>
          </div>
          <p className="font-medium">Cross-Validation Workflow</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Implement cross-validation techniques to address overfitting.</p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Fine-tune the machine learning models.</p>
          </div>
        </div>
        <div className="border-2 border-gray-300 rounded-lg h-full flex flex-col p-4 text-left leading-loose">
          <h1
            className=" md:text-xl font-bold lg:pr-20 leading-tight mb-10"
            style={{ color: "#6C01D4" }}
          >
            Phase 3: Testing and Optimization
          </h1>
          <p className="font-medium">Internal Testing</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Conduct rigorous internal testing of the MinerGate Protocol.</p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Identify and address any bugs or issues.</p>
          </div>
          <p className="font-medium">External Beta Testing</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Invite a select group of users and partners to participate in beta
              testing.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Gather valuable feedback for further optimization.</p>
          </div>
        </div>
        <div className="border-2 border-gray-300 rounded-lg h-full flex flex-col p-4 text-left leading-loose">
          <h1
            className=" md:text-xl font-bold lg:pr-20 leading-tight mb-10"
            style={{ color: "#6C01D4" }}
          >
            Phase 4: Launch and Expansion
          </h1>
          <p className="font-medium">Protocol Launch</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Officially launch the MinerGate Protocol.</p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Make it available to the broader blockchain and crypto community.
            </p>
          </div>
          <p className="font-medium">User Onboarding and Support</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Focus on user education and support to ensure smooth adoption.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Provide resources, tutorials, and customer assistance.</p>
          </div>
          <p className="font-medium">Strategic Growth</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Expand MinerGate's ecosystem by integrating with more exchanges,
              DEXes, and DeFi projects.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Explore opportunities for global expansion.</p>
          </div>
        </div>
        <div className="border-2 border-gray-300 rounded-lg h-full flex flex-col p-4 text-left leading-loose">
          <h1
            className=" md:text-xl font-bold lg:pr-20 leading-tight mb-10"
            style={{ color: "#6C01D4" }}
          >
            Phase 5: Continuous Improvement and Innovation
          </h1>
          <p className="font-medium">Ongoing Security Enhancements</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Continuously monitor and improve the MinerGate Protocol's security
              features.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Stay up-to-date with emerging threats and vulnerabilities.</p>
          </div>
          <p className="font-medium">Feature Enhancements</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Gather user feedback and suggestions for feature enhancements.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Implement updates and improvements to meet evolving needs.</p>
          </div>
        </div>
        <div className="border-2 border-gray-300 rounded-lg h-full flex flex-col p-4 text-left leading-loose">
          <h1
            className=" md:text-xl font-bold lg:pr-20 leading-tight mb-10"
            style={{ color: "#6C01D4" }}
          >
            Phase 6: Community Engagement and Awareness
          </h1>
          <p className="font-medium">Community Building</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Foster an active and engaged MinerGate community.</p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Organize events, webinars, and forums for users and developers.
            </p>
          </div>
          <p className="font-medium">Educational Initiatives</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Launch educational campaigns to promote blockchain security
              awareness.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Share insights and best practices through articles, videos, and
              webinars.
            </p>
          </div>
        </div>
        <div className="border-2 border-gray-300 rounded-lg h-full flex flex-col p-4 text-left leading-loose">
          <h1
            className=" md:text-xl font-bold lg:pr-20 leading-tight mb-10"
            style={{ color: "#6C01D4" }}
          >
            Phase 7: Long-Term Sustainability
          </h1>
          <p className="font-medium">Sustainability Measures</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Develop a sustainable funding model for ongoing project
              maintenance and development.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Develop a sustainable funding model for ongoing project
              maintenance and development.
            </p>
          </div>
          <p className="font-medium">Research and Innovation</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Continue research into cutting-edge blockchain security
              technologies.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Stay at the forefront of the industry's advancements.</p>
          </div>
        </div>
        <div className="border-2 border-gray-300 rounded-lg h-full flex flex-col p-4 text-left leading-loose">
          <h1
            className=" md:text-xl font-bold lg:pr-20 leading-tight mb-10"
            style={{ color: "#6C01D4" }}
          >
            Phase 1: Project Inception and Foundation
          </h1>
          <p className="font-medium">Project Planning and Team Formation</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Assemble a dedicated team of developers, cybersecurity experts,
              and blockchain specialists.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Define project goals and objectives.</p>
          </div>
          <p className="font-medium">Market Research and Analysis</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Conduct an in-depth analysis of the blockchain and crypto security
              markets.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Identify key competitors and potential partners.</p>
          </div>
          <p className="font-medium">Establish Strategic Partnerships</p>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>
              Forge partnerships with blockchain platforms, exchanges, and
              cybersecurity firms.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#6C01D4"
                style={{ color: "#6C01D4", height: "1em", width: "1em" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
            </div>
            <p>Lay the groundwork for collaboration and integration.</p>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Roadmap;
