import React from "react";
import ReasonImg from "../assets/ReasonsImg.webp";

const Reason = () => {
    return (
        <>
            <div className="px-4 md:px-6">
                <div className="flex flex-col-reverse gap-4 lg:flex-row py-16 px-4 md:px-6">
                    <div className="w-full lg:w-1/2 mt-4 md:mt-0 md:order-1">
                        <img src={ReasonImg} alt="ReasonImg" className="p-16" />
                    </div>
                    <div className="w-full lg:w-1/2 p-8 justify-center md:order-2">
                        <h1 className="lg:text-5xl text-2xl font-bold pr-20 leading-tight mb-6">
                            Reasons To Use<br/>
                            <span
                                style={{
                                    WebkitTextFillColor: "transparent",
                                    WebkitBackgroundClip: "text",
                                    backgroundImage:
                                        "linear-gradient(279deg, #6c01d4, #9b1fb7 25%, #c3399f 50%, #db4e6a 75%, #f16337)",
                                }}
                            >
                                MinerGate's WEB3 Tools
                            </span>
                        </h1>
                        <h1 className="font-bold">
                            Bring your infrastructure in-app
                        </h1>
                        <p className="my-3 text-justify">
                            Many web3 wallets and infrastructure work only on browser. But the MinerGate Mobile SDK ensures your stack is natively supported in your app.
                        </p>
                        <h1 className="font-bold">
                            Built for where your users go
                        </h1>
                        <p className="my-3 text-justify">
                            Whether your app is mobile only or available across platforms like PC and console, the MinerGate's tools ensures a seamless experience for your users.
                        </p>
                        <h1 className="font-bold">
                            Grow, retain, and monetize your players
                        </h1>
                        <p className="my-3 text-justify">
                            Delight your players with tradable items, unique rewards, and player-driven economies to drive engagement, retention, and monetization.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reason;
