import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.scss";
import { Link } from "react-router-dom";


function SliderPress() {

    const settings = {
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1304,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="page">
            <Slider className="card-slider" {...settings}>
                <div className="card-wrapper">
                    <Link to="/">
                        <article
                            className="card bg-cover bg-center bg-no-repeat hover:text-white flex flex-col items-start rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
                        >
                            <div className="content card-overlay ">
                                <button className="title transition duration-300 rounded-lg text-sm p-2" style={{color: "#491BCF", backgroundColor: "#EEE8FF"}}> ANNOUNCEMENT</button>
                                <h3 className="title text-xl text-black hover:text-white font-semibold transform-translate-y-2 transition-transform duration-300 pb-2">
                                The Multifaceted Utility of $MGT: Exploring MinerGate Token Use Cases
                                </h3>
                                <div className="items-center">

                            <p className="title text-black">3 min read</p>
                            <p className="title" style={{color: "#491BCF"}}>Read more</p>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
                <div className="card-wrapper">
                    <Link to="/">
                        <article
                            className="card bg-cover bg-center bg-no-repeat flex flex-col items-start rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
                        >
                            <div className="content card-overlay ">
                                <button className="title transition duration-300 rounded-lg text-sm p-2" style={{color: "#491BCF", backgroundColor: "#EEE8FF"}}> ANNOUNCEMENT</button>
                                <h3 className="title text-xl text-black hover:text-white font-semibold transform-translate-y-2 transition-transform duration-300 pb-2">
                                Sustainability in Blockchain: How MinerGate Is Reducing Its Environmental Footprint
                                </h3>
                                <div className="items-center">

                            <p className="title text-black">3 min read</p>
                            <p className="title" style={{color: "#491BCF"}}>Read more</p>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
                <div className="card-wrapper">
                    <Link to="/">
                        <article
                            className="card bg-cover bg-center bg-no-repeat flex flex-col items-start rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
                        >
                            <div className="content card-overlay ">
                                <button className="title transition duration-300 rounded-lg text-sm p-2" style={{color: "#491BCF", backgroundColor: "#EEE8FF"}}> ANNOUNCEMENT</button>
                                <h3 className="title text-xl text-black hover:text-white font-semibold transform-translate-y-2 transition-transform duration-300 pb-2">
                                The Role of MinerGate in Shaping the Future of Cryptocurrency
                                </h3>
                                <div className="items-center">

                            <p className="title text-black">3 min read</p>
                            <p className="title " style={{color: "#491BCF"}}>Read more</p>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
                <div className="card-wrapper">
                    <Link to="/">
                        <article
                            className="card bg-cover bg-center bg-no-repeat flex flex-col items-start rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
                        >
                            <div className="content card-overlay ">
                                <button className="title transition duration-300 rounded-lg text-sm p-2" style={{color: "#491BCF", backgroundColor: "#EEE8FF"}}> ANNOUNCEMENT</button>
                                <h3 className="title text-xl text-black hover:text-white font-semibold transform-translate-y-2 transition-transform duration-300 pb-2">
                                Strategies for Successful Cloud Mining on MinerGate
                                </h3>
                                <div className="items-center">

                            <p className="title text-black">3 min read</p>
                            <p className="title " style={{color: "#491BCF"}}>Read more</p>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
                <div className="card-wrapper">
                    <Link to="/">
                        <article
                            style={{

                            }}
                            className="card bg-cover bg-center bg-no-repeat flex flex-col items-start rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
                        >
                            <div className="content card-overlay ">
                                <button className="title transition duration-300 rounded-lg text-sm p-2" style={{color: "#491BCF", backgroundColor: "#EEE8FF"}}> ANNOUNCEMENT</button>
                                <h3 className="title text-xl text-black hover:text-white font-semibold transform-translate-y-2 transition-transform duration-300 pb-2">
                                Security first: How MinerGate Ensures the Safety of Your Assets
                                </h3>
                                <div className="items-center">

                            <p className="title text-black">3 min read</p>
                            <p className="title " style={{color: "#491BCF"}}>Read more</p>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
                <div className="card-wrapper">
                    <Link to="/">
                        <article
                            className="card bg-cover bg-center bg-no-repeat flex flex-col items-start rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
                        >
                            <div className="content card-overlay ">
                                <button className="title transition duration-300 rounded-lg text-sm p-2" style={{color: "#491BCF", backgroundColor: "#EEE8FF"}}> ANNOUNCEMENT</button>
                                <h3 className="title text-xl text-black hover:text-white font-semibold transform-translate-y-2 transition-transform duration-300 pb-2">
                                Exploring DeFi: MinerGate’s Dive into Decentralized Finance
                                </h3>
                                <div className="items-center">

                            <p className="title text-black">3 min read</p>
                            <p className="title " style={{color: "#491BCF"}}>Read more</p>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
                <div className="card-wrapper">
                    <Link to="/">
                        <article
                            className="card bg-cover bg-center bg-no-repeat flex flex-col items-start rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
                        >
                            <div className="content card-overlay ">
                                <button className="title transition duration-300 rounded-lg text-sm p-2" style={{color: "#491BCF", backgroundColor: "#EEE8FF"}}> ANNOUNCEMENT</button>
                                <h3 className="title text-xl text-black hover:text-white font-semibold transform-translate-y-2 transition-transform duration-300 pb-2">
                                Demystifying Cloud Mining: How MinerGate Simplifies Cryptocurrency Mining
                                </h3>
                                <div className="items-center">

                            <p className="title text-black">3 min read</p>
                            <p className="title " style={{color: "#491BCF"}}>Read more</p>
                                </div>
                            </div>
                        </article>
                    </Link>
                </div>
            </Slider>
        </div>
    );
}

export default SliderPress;
