import React from "react";
import ReinforceIcon1 from "../assets/ReinforceIcon.webp";
import ReinforceIcon2 from "../assets/ReinforceIcon2.webp";
import ReinforceIcon3 from "../assets/ReinforceIcon3.webp";
import ReinforceIcon4 from "../assets/ReinforceIcon4.webp";
import ReinforceIcon5 from "../assets/ReinforceIcon5.webp";
import ReinforceIcon6 from "../assets/ReinforceIcon6.webp";
import Miner from "../assets/Miner.gif";

const Asimov = () => {
    return (
        <section className="relative h-full" style={{ backgroundColor: "#0d1117" }}>
            <div className="w-20 h-20 lg:visible md:invisible">
                <svg
                    viewBox="0 0 151 145"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M76.5067 72.5H151V0H76.5067H74.4933H0V72.5V145H76.5067V72.5Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
            <div className="pl-10">
                <p className="text-slate-400">WHY ASIMOV</p>
                <div className="flex lg:flex-row flex-col">
                    <div className="lg:w-1/2 text-white">
                        <h1 className="font-medium lg:text-5xl text-2xl lg:leading-snug md:leading-snug mb-8">
                            <span style={{ color: "#f77d6e" }}>Reinforce your</span>
                            <br />
                            protocol security
                        </h1>
                        <p className="md:text-2xl pr-1">
                            MinerGate offers on-chain fail-safes for your token contracts and
                            mitigation tools, in addition to providing real-time monitoring of
                            all of your mission-critical contracts.
                        </p>
                        <div className="flex flex-col lg:flex-row items-center justify-center w-full md:px-14 lg:p-14  pr-2 pt-4">
                            <div className="w-full text-center text-xl flex flex-col items-center">
                                <img
                                    src={ReinforceIcon1}
                                    alt="ReinforceIcon1"
                                    className="mb-4"
                                />
                                <p className="font-medium">
                                    Seamless ERC20 standard integration
                                </p>
                            </div>
                            <div className="w-full text-center text-xl flex flex-col items-center mt-8 lg:mt-0 lg:ml-8">
                                <img
                                    src={ReinforceIcon2}
                                    alt="ReinforceIcon2"
                                    className="mb-4"
                                />
                                <p className="font-medium">
                                    Automated real-time freezing of suspicious transactions
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row items-center justify-center w-full md:px-14 lg:p-14 pr-2 ">
                            <div className="w-full text-center text-xl flex flex-col items-center">
                                <img
                                    src={ReinforceIcon3}
                                    alt="ReinforceIcon3"
                                    className="mb-4"
                                />
                                <p className="font-medium">
                                    Efficient and evidence-based resolution mechanism
                                </p>
                            </div>
                            <div className="w-full text-center text-xl flex flex-col items-center mt-8 lg:mt-0 lg:ml-8">
                                <img
                                    src={ReinforceIcon4}
                                    alt="ReinforceIcon4"
                                    className="mb-4"
                                />
                                <p className="font-medium">Timely recovery of stolen funds</p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row items-center justify-center w-full md:px-14 lg:p-14  pr-2 pb-4">
                            <div className="w-full text-center text-xl flex flex-col items-center">
                                <img
                                    src={ReinforceIcon5}
                                    alt="ReinforceIcon5"
                                    className="mb-4"
                                />
                                <p className="font-medium">
                                    Fees only charged on prevented financial loss
                                </p>
                            </div>
                            <div className="w-full text-center text-xl flex flex-col items-center mt-8 lg:mt-0 lg:ml-8">
                                <img
                                    src={ReinforceIcon6}
                                    alt="ReinforceIcon6"
                                    className="mb-4"
                                />
                                <p className="font-medium">
                                    Advanced exploit identification and mitigation tools
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:pl-10 pt-40 flex justify-center items-center">
                        <img
                            src={Miner}
                            alt="Miner"
                            className="shadow-md"
                        />
                    </div>


                </div>
            </div>
        </section>
    );
};

export default Asimov;
