import React from "react";
import Choose1 from "../assets/chooseus.png";
import Line from "../assets/line.png";
import BitcoinSkateboard from "../assets/BitcoinSkateboard.webp";

const ChooseUsBg = () => {
    return (
        <section className="relative h-full" style={{ backgroundColor: "#0d1117" }}>
            
            <img src={Choose1} alt="chooseus" className="absolute inset-0 w-full h-full object-cover" />

            <div className="flex flex-col lg:flex-row relative items-center justify-items-center">
                <div className="lg:w-2/3 md:w-full lg:pl-32 md:pl-16 relative z-20">
                    {/* Line image */}
                    <img src={Line} alt="line" className="z-10" />
                </div>

                <div className="lg:w-1/2 hidden lg:block"> 
                    {/* Skateboard image */}
                    <img src={BitcoinSkateboard} alt="Skateboard" className="lg:w-2/4" />
                </div>
            </div>
        </section>
    );
};

export default ChooseUsBg;
