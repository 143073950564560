import React from 'react';
import Hero from '../components/hero';
import Navbar from '../components/navbar';
import RiskScoring from '../components/riskscoring';
import ChooseUs from '../components/chooseus';
import ChooseUsBg from '../components/chooseusbg';
import CloudApiBox from '../components/CloudApi';
import CloudConsoleBox from '../components/CloudConsole';
import Build from '../components/build';
import ChooseBg from '../components/choosebg2';
import Reason from '../components/reason';
import Tooling from '../components/tooling';
import Asimov from '../components/asimov';
import Dashboard from '../components/dashboard';
import Press from '../components/press';
import Roadmap from '../components/roadmap';
import Footer from '../components/footer';
import ToolingDesc from '../components/toolingDesc';

function Home() {
  return (
    <div>
<Navbar/>
    <Hero/>
    <RiskScoring/>
    <ChooseUsBg/>
    <div className='lg:pr-32' 
            style={{ backgroundColor: "#0d1117" }}>

    <ChooseUs/>
    <ToolingDesc/>
    <Tooling/>
    <CloudApiBox/>
    <CloudConsoleBox/>
    <Build/>
    </div>
    <ChooseBg/>
    <Reason/>
    <Asimov/>
    <Dashboard/>
    <Press/>
    <Roadmap/>
    <Footer/>
    </div>
  );
}

export default Home;
