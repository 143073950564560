import React, { useEffect } from "react";
import scrollSvg from "scroll-svg";
import CloudApi from "../assets/CloudApi.webp";

const CloudApiBox = () => {
  useEffect(() => {
    const svgPath = document.querySelector("#scroll-line");
    const scrolledSvg = scrollSvg(svgPath);
    return () => {
      scrolledSvg.stopAnimating();
    };
  }, []);

  return (
    <section
      className="h-full lg:pl-32 md:p-16 p-1"
      style={{ backgroundColor: "#0d1117" }}
    >
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-row">
          <div className="w-20 md:ml-6">
            <svg
              viewBox="0 0 109 748"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_253_4403)">
                <path 
                className="w-1"
                  id="scroll-line"
                  d="M2.69162 89L2.69147 130.609C2.69136 164.729 15.5446 197.597 38.691 222.665C61.8372 247.735 74.6905 280.602 74.6905 314.723V463.477C74.6905 497.969 61.8584 531.228 38.691 556.783C15.5237 582.336 2.69157 615.596 2.69149 650.089L2.69141 691.063"
                  stroke="url(#paint0_linear_2_3)"
                  stroke-width="3.7241"
                />
                <g filter="url(#filter0_dddd_253_4403)">
                  <path
                    d="M75.2342 382.118C79.648 382.118 83.2262 385.696 83.2262 390.11C83.2262 394.523 79.648 398.102 75.2342 398.102C70.8203 398.102 67.2422 394.523 67.2422 390.11C67.2422 385.696 70.8203 382.118 75.2342 382.118Z"
                    fill="black"
                  ></path>
                  <path
                    d="M75.2315 383.978C78.617 383.978 81.3614 386.723 81.3614 390.108C81.3614 393.494 78.617 396.238 75.2315 396.238C71.846 396.238 69.1016 393.494 69.1016 390.108C69.1016 386.723 71.846 383.978 75.2315 383.978Z"
                    stroke="white"
                    stroke-width="3.7241"
                  ></path>
                </g>
              </g>
              <rect
                id="scroll-line"
                className="w-1"
                x="1"
                height="748"
                stroke="url(#paint1_linear_2_3)"
                stroke-width="3.7241"
              ></rect>
              <defs>
                <filter
                  id="filter0_dddd_253_4403"
                  x="42.411"
                  y="357.29"
                  width="65.6429"
                  height="65.639"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feOffset></feOffset>
                  <feGaussianBlur stdDeviation="9.31024"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.337255 0 0 0 0 0.827451 0 0 0 0 0.392157 0 0 0 1 0"
                  ></feColorMatrix>
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_253_4403"
                  ></feBlend>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feOffset></feOffset>
                  <feGaussianBlur stdDeviation="12.4137"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.337255 0 0 0 0 0.827451 0 0 0 0 0.392157 0 0 0 1 0"
                  ></feColorMatrix>
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_253_4403"
                    result="effect2_dropShadow_253_4403"
                  ></feBlend>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feOffset></feOffset>
                  <feGaussianBlur stdDeviation="3.10341"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.337255 0 0 0 0 0.827451 0 0 0 0 0.392157 0 0 0 1 0"
                  ></feColorMatrix>
                  <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_253_4403"
                    result="effect3_dropShadow_253_4403"
                  ></feBlend>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feOffset></feOffset>
                  <feGaussianBlur stdDeviation="9.31024"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.337255 0 0 0 0 0.827451 0 0 0 0 0.395098 0 0 0 1 0"
                  ></feColorMatrix>
                  <feBlend
                    mode="normal"
                    in2="effect3_dropShadow_253_4403"
                    result="effect4_dropShadow_253_4403"
                  ></feBlend>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect4_dropShadow_253_4403"
                    result="shape"
                  ></feBlend>
                </filter>
                <linearGradient
                  id="paint0_linear_2_3"
                  x1="30.0013"
                  y1="89"
                  x2="28.1393"
                  y2="691.062"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.0515742"
                    stop-color="#196C2E"
                    stop-opacity="0"
                  ></stop>
                  <stop offset="0.225992" stop-color="#2EA043"></stop>
                  <stop offset="0.47249" stop-color="#2EA043"></stop>
                  <stop offset="0.47249" stop-color="#2EA043"></stop>
                  <stop offset="0.522324" stop-color="#56D364"></stop>
                  <stop offset="0.561417" stop-color="#2EA043"></stop>
                  <stop offset="0.791714" stop-color="#2EA043"></stop>
                  <stop
                    offset="0.956186"
                    stop-color="#196C2E"
                    stop-opacity="0"
                  ></stop>
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2_3"
                  x1="3"
                  y1="0"
                  x2="3"
                  y2="748"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#0D1117"></stop>
                  <stop offset="0.333333" stop-color="#2EA043"></stop>
                  <stop offset="0.666667" stop-color="#2EA043"></stop>
                  <stop offset="1" stop-color="#0D1117"></stop>
                </linearGradient>
                <clipPath id="clip0_253_4403">
                  <rect
                    width="107.999"
                    height="602.062"
                    fill="white"
                    transform="translate(0.207031 89)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <p
            className="lg:text-5xl text-xl lg:mt-60 mt-40 ml-10 w-1/2"
            style={{ color: "#7EE787" }}
          >
            Infrastructure Stack
          </p>
        </div>
        <div className="lg:w-1/2 lg:ml-20 m-4">
          <div className="border-2 border-gray-700 bg-gray-800 rounded-lg pt-10 pl-10">
            <h1 className="text-2xl text-white font-medium">API</h1>
            <p className="text-xl text-slate-400 mt-5">
              Use our API to build applications using our platform.
            </p>
            <img src={CloudApi} alt="Cloudapi" className="pt-20" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CloudApiBox;
