import React from "react";
import SliderPress from "./Slider";
import Bag from "../assets/Bag.webp"

const Press = () => {
    return (
        <section className="h-full lg:pl-32 md:pl-16 p-4">
            <div className="flex lg:flex-row flex-col-reverse">
                <div className="flex flex-col justify-end">

                <p className="text-slate-400 md:text-xl">Read About MinerGate</p>
                <h1 className="lg:text-6xl text-3xl font-bold pr-20 leading-tight mb-10">
                    In the{" "}
                    <span
                        style={{
                            WebkitTextFillColor: "transparent",
                            WebkitBackgroundClip: "text",
                            backgroundImage:
                                "linear-gradient(90deg, #EA6788 0%, #853FFD 46.01%)",
                        }}
                    >
                        press
                    </span>
                </h1>
                            </div>
                            <div className="flex justify-center">
<img src={Bag} alt="Bag" className="w-1/3" />
                            </div>
            </div>
            <div>
                <a href="/">
                <button
                    className="px-5 py-2 transition duration-300 rounded-full text-white"
                    style={{ backgroundColor: "#5A01A6" }}
                >
                    News
                </button></a>
            </div>
            <div className="p-10">

            <SliderPress/>
            </div>
        </section>
    );
};

export default Press;
